import React from "react"
import { Layout } from "@components/layout"
import { wrapper } from "./styles/rodo.module.scss"

const Returns = ({ pageContext }) => {
  const { page } = pageContext
  console.log("page", page)

  return (
    <Layout {...page}>
      <div className={wrapper}>
        <h1>Regulamin zwrotów</h1>
        <iframe src="http://gladiatorzy.api.localhost-group.com/wp-content/uploads/2020/07/Regulamin-sklepu-internetowego.pdf"></iframe>
      </div>
    </Layout>
  )
}

export default Returns
